/*
Extract and validate tokens in the URL if they are present.
*/
import store from "../store";
import router from "../router";
// import { updateListMemberMailchimp } from "@/services/mailchimpServices.js";

/**
 * Reads the URL hash attempts and tries to detect if there is confirmation tokens from either an email signup or
 * external provider. If present it will call the relevant process to attempt to authorize the token.
 */
function detectTokens() {
  const inviteToken = detectInviteToken();
  const emailToken = detectEmailConfirmationToken();
  const recoveryToken = detectRecoveryToken();
  const emailChangeToken = detectEmailChangeToken();

  if (emailToken) {
    console.log("Detected email confirmation token", emailToken);
    confirmEmailToken(emailToken);
    return;
  } else if (inviteToken) {
    console.log("Detected accept invite token", inviteToken);
    confirmInviteToken(inviteToken);
    return;
  } else if (recoveryToken) {
    console.log("Detected recovery token", recoveryToken);
    confirmRecoveryToken(recoveryToken);
    return;
  } else if (emailChangeToken) {
    console.log("Detected email change token", emailChangeToken);
    confirmEmailChangeToken(emailChangeToken);
    return;
  }

  console.log("No tokens detected in URL hash");
}

function detectInviteToken() {
  try {
    // split the hash where it detects `invite_token=`. The string which proceeds is the part which we want.
    const token = decodeURIComponent(document.location.hash).split(
      "invite_token="
    )[1];
    return token;
  } catch (error) {
    console.error(
      "Something went wrong when trying to extract accept invite.",
      error
    );
    return null;
  }
}

/**
 * Checks URL hash for `confirmation_token=` then extracts the token which proceeds.
 */
function detectEmailConfirmationToken() {
  try {
    // split the hash where it detects `confirmation_token=`. The string which proceeds is the part which we want.
    const token = decodeURIComponent(document.location.hash).split(
      "confirmation_token="
    )[1];
    return token;
  } catch (error) {
    console.error(
      "Something went wrong when trying to extract email confirmation email",
      error
    );
    return null;
  }
}

function detectRecoveryToken() {
  try {
    // split the hash where it detects `recovery_token=`. The string which proceeds is the part which we want.
    const token = decodeURIComponent(document.location.hash).split(
      "recovery_token="
    )[1];
    return token;
  } catch (error) {
    console.error(
      "Something went wrong when trying to extract email recovery email",
      error
    );
    return null;
  }
}

/**
 * Checks URL hash for `email_change_token=` then extracts the token which proceeds.
 */
function detectEmailChangeToken() {
  try {
    // split the hash where it detects `email_change_token=`. The string which proceeds is the part which we want.
    const token = decodeURIComponent(document.location.hash).split(
      "email_change_token="
    )[1];
    return token;
  } catch (error) {
    console.error(
      "Something went wrong when trying to extract email confirmation email",
      error
    );
    return null;
  }
}

function confirmInviteToken(inviteToken) {
  store
    .dispatch("auth/attemptAcceptInvite", inviteToken)
    .then(res => {
      // console.log("RESPONSE FROM INVITE ACCEPT", res);
      updateUserMetaAfterInvite(res.email);
      // router.push({
      //   path: `/login`,
      //   query: { inviteAccepted: "true", email: res.email }
      // });
    })
    .catch(() => {
      alert(`Can't confirm invite. Please contact support.`);
    });
}

// After user accepts invite, need to set
function updateUserMetaAfterInvite(email) {
  const userData = {
    // data prop updates netlify identity user_metadata
    data: {
      emailBeforeChange: email
    }
  };

  store
    .dispatch("auth/updateUserAccount", { ...userData })
    .then(res => {
      console.log("res from updating user meta after invite", res);

      router.push({
        path: `/login`,
        query: { inviteAccepted: "true", email: res.email }
      });
    })
    .catch(() => {
      alert(
        `Can't update user info after invite acceptance. Please contact support.`
      );
    });
}

/**
 * @param {string} token - authentication token used to confirm a user who has created an account via email signup.
 */
function confirmEmailToken(token) {
  store
    .dispatch("auth/attemptConfirmation", token)
    .then(res => {
      alert(`${res.email} has been confirmed, please login`);
    })
    .catch(error => {
      alert(`Can't authorize your account right now. Please try again`);
      console.error(error, "Somethings gone wrong logging in");
    });
}

function confirmRecoveryToken(recoveryToken) {
  store
    .dispatch("auth/attemptPasswordRecovery", recoveryToken)
    .then(() => {
      router.push("profile?pw_update=true");
      alert("Account has been recovered. Update your password now.");
    })
    .catch(() => {
      alert(`Can't recover password. Please contact support.`);
    });
}

/**
 * @param {string} token - authentication token used to confirm a user who wants to change their email
 */
function confirmEmailChangeToken(token) {
  // console.log("Next, confirm email change token: ", token);
  store
    .dispatch("auth/attemptEmailChange", token)
    .then(res => {
      // After email is confirmed, need to update Mailchimp email address.
      // Need to use previous email from user_metadata for MC to look it up
      // and new email address that was just changed.
      console.log("Email change confirmed", res);
      console.log("STORE FROM STORE", store);
      store.dispatch("auth/updateEmailChangeConfirmed", res.email).then(res => {
        console.log(
          "updateEmailChangeConfirmed has been committed by action",
          res
        );
      });
    })
    .catch(error => {
      alert(`Can't change your email right now. Please try again`);
      console.error(error, "Something has gone wrong logging in");
    });
}

export default function() {
  detectTokens();
}
