// import Vue from "vue";

const getDefaultState = () => {
  return {
    hasSeenAdvisory: false,
    advisorySystemLevels: [
      {
        active: true,
        level: "Green",
        color: "green",
        systemDesc: "No Formal Social Security Change Activity",
        reason:
          "No pending legislation, executive orders or formal proposed changes from Executive or Legislative government branches.",
        response:
          "PlanGap provides executive summary of the annual Trustee Report and analysis of relevant legislation."
      },
      {
        active: false,
        level: "Blue",
        color: "blue",
        systemDesc: "Social Security Change Activity in Discussion",
        reason:
          "Commission formed and/or legislation debated in committee that could result in a reduction of benefits.",
        response:
          "PlanGap provides analysis on important updates to Carrier as needed."
      },
      {
        active: false,
        level: "Yellow",
        color: "#f8d640",
        systemDesc: "Social Security Change Activity in Progress",
        reason:
          "Commission releases verdicts and/or legislation has passed committee that will most likely make changes to Social Security.",
        response:
          "PlanGap works with Carrier on simulations to estimate effect on Carrier’s specific book of business."
      },
      {
        active: false,
        level: "Orange",
        color: "warning",
        systemDesc: "Social Security Change Activity Has Passed",
        reason:
          "Congress has passed legislation and has been signed into law by the President.",
        response:
          "PlanGap coordinates with Carrier to provide guidance on claims processing and inbound customer service requests."
      },
      {
        active: false,
        level: "Red",
        color: "error",
        systemDesc: "Social Security Changes in Effect",
        reason:
          "Social Security benefit reductions are in effect and policy bonus should be paid out if elected.",
        response:
          "PlanGap remains available to the Carrier throughout the product lifecycle."
      }
    ]
  };
};

export default {
  strict: false,
  namespaced: true,

  state() {
    return getDefaultState();
  },

  getters: {
    hasSeenAdvisory: state => state.hasSeenAdvisory,
    advisorySystemLevels: state => state.advisorySystemLevels
  },

  mutations: {
    SET_HAS_SEEN_ADVISORY(state, value) {
      state.hasSeenAdvisory = value;
    },

    SET_ACTIVE_ALERT_LEVEL(state, value) {
      // value is the index of object to turn active passed in

      for (let index = 0; index < state.advisorySystemLevels.length; index++) {
        if (index === value) {
          state.advisorySystemLevels[index].active = true;
        } else {
          state.advisorySystemLevels[index].active = false;
        }
      }
    }
  },

  actions: {
    setHasSeenAdvisory({ commit }, payload) {
      commit("SET_HAS_SEEN_ADVISORY", payload);
    },

    setActiveAlertLevel({ commit }, payload) {
      commit("SET_ACTIVE_ALERT_LEVEL", payload);
    }
  }
};
