<template>
  <BaseAppLayout>
    <router-view></router-view>
  </BaseAppLayout>
</template>

<script>
import { mapGetters } from "vuex";
import { updateListMemberMailchimp } from "@/services/mailchimpServices.js";

export default {
  name: "App",

  data() {
    return {
      //backgroundGradient: `radial-gradient(farthest-side ellipse at 10% 0, #2e4e86, #253667)`, //#253667
      //backgroundGradient: `radial-gradient(farthest-side ellipse at 10% 0, #152146, #0a1023)`,
      // backgroundGradient: `radial-gradient(farthest-side ellipse at 10% 0,rgb(32 62 97), rgb(11 33 60))`,
      unsubscribeMutations: null,
    };
  },
  computed: {
    ...mapGetters("auth", ["currentUser", "emailChangeConfirmed"]),
  },
  mounted() {
    // console.log(this.$vuetify.application.top);
    // console.log(this.$vuetify.breakpoint.width);
    // console.log(this.$vuetify.theme.themes.dark);
    // ${this.$vuetify.theme.themes.dark.background}
    this.subscribeToMutations();

    this.unsubscribeMutations = this.$store.subscribe((mutation) => {
      // console.log(mutation.type);
      // console.log(mutation.payload);

      if (mutation.type === "auth/SET_EMAIL_CHANGE_CONFIRMED") {
        this.checkForEmailConfirmation();
      }
    });
  },
  beforeDestroy() {
    this.unsubscribeMutations();
  },
  methods: {
    subscribeToMutations() {},
    async checkForEmailConfirmation() {
      // If there is a newly confirmed email change,
      // update it in Mailchimp
      if (this.emailChangeConfirmed) {
        const mcUpdateData = {
          email: this.currentUser.user_metadata.emailBeforeChange,
          data: {
            email_address: this.emailChangeConfirmed,
            status: "subscribed",
          },
        };

        try {
          const mcUpdated = await updateListMemberMailchimp(mcUpdateData);
          console.log("mcUpdated", mcUpdated);
          if (mcUpdated.email) {
            alert(`Your email address has been changed to ${mcUpdated.email}!`);
            // TODO Need to update user_metadata.emailBeforeChange so it is now
            // set to be the NEW confirmed email
            // all update in Auth store

            // Also update state to remove email from emailchangeconfirmed prop
          }
        } catch (error) {
          alert(
            `Can't change your subscriptions email right now. Please contact support.`
          );
          console.error(error, "Something has gone changing MC email.");
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.v-toolbar {
  &.v-app-bar--is-scrolled {
    background: rgba(#1b284e, 0.97) !important;
  }
}
</style>

<style lang="scss">
body {
  background: #12141b;
}
.theme--dark {
  &.v-sheet:not(.v-alert),
  &.v-card {
    color: #d6deec !important;
  }
}
</style>
