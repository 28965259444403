const getDefaultState = () => {
  return {
    isDevEnvironment: false,
    siteURL: null,
    mailchimpListInterests: [],
    initialClaimsTableData: null
  };
};

export default {
  strict: false,
  namespaced: true,

  state() {
    return getDefaultState();
  },

  getters: {
    isDevEnvironment: state => state.isDevEnvironment,
    siteURL: state => state.siteURL,
    mailchimpListInterests: state => state.mailchimpListInterests,
    initialClaimsTableData: state => state.initialClaimsTableData
  },

  mutations: {
    RESET_APP_STATE(state) {
      Object.assign(state, getDefaultState());
    },
    SET_DEV_ENV(state, value) {
      state.isDevEnvironment = value;
    },
    SET_SITE_URL(state, value) {
      state.siteURL = value;
    },
    SET_MAILCHIMP_LIST_INTERESTS(state, value) {
      state.mailchimpListInterests = value;
    },
    SET_INITIAL_BIRTH_YEAR_DATA(state, value) {
      state.initialClaimsTableData = value;
    }
  },

  actions: {
    resetAppState({ state, commit }) {
      // console.log("SHOULD BE RESETTING APP STATE");
      commit("RESET_APP_STATE", state);
    },
    setMailchimpListInterests({ commit }, interests) {
      commit("SET_MAILCHIMP_LIST_INTERESTS", interests);
    },
    setInitialClaimsTableData({ commit }, data) {
      commit("SET_INITIAL_BIRTH_YEAR_DATA", data);
    }
  }
};
