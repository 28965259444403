import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: { variations: true },
    themes: {
      dark: {
        primary: "#50cbff",
        secondary: "#33B469",
        background: "#141d38", //"#0b213c",
        dashCard: "#1e2e5d", //"#19254a", //"#12192f", //"#0a1c33",
        anchor: "#50cbff",
        accent: "#ff06df"
      }
    },
    dark: true
  }
});
