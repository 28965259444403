import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
  // { path: "/", redirect: "/get-insights" },
  {
    path: "/",
    name: "landing",
    component: () => import("../views/Landing.vue"),
    meta: { layout: "AppLayoutExternal" },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/Login.vue"),
    meta: { layout: "AppLayoutExternal" },
  },
  {
    path: "/get-insights",
    name: "getInsights",
    component: () => import("../views/Landing.vue"),
    meta: { layout: "AppLayoutExternal" },
  },
  {
    path: "/access",
    name: "access",
    component: () => import("../views/Access.vue"),
    meta: { layout: "AppLayoutExternal" },
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("../views/Dashboard.vue"),
    meta: { authRequired: true, layout: "AppLayoutDashboard" },
  },

  {
    path: "/simulation/:id?",
    name: "simulation",
    component: () => import("../views/Simulation.vue"),
    meta: { authRequired: true, layout: "AppLayoutDashboard" },
    props: true,
  },
  { path: "*", redirect: "/" },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const authRequired = to.matched.some((route) => route.meta.authRequired);
  // const isTempAuth = localStorage.getItem("pgi-auth");
  // If the route doesn't have a `meta.authRequired` property go on ahead!
  // If the user is logged in and they hit /login, redirect to dashboard
  if (!authRequired) {
    if (
      (to.name === "login" || to.name === "landing" || to.name === "access") &&
      store.getters["auth/loggedIn"]
    ) {
      return next({ name: "dashboard" });
    } else {
      return next();
    }
  }
  // If we go this far then it must have the `meta.authRequired`. But is there is a user logged in? If so, then go right on ahead!
  if (store.getters["auth/loggedIn"]) {
    return next();
  }
  // The page requested is both secured and there is no logged in user detected. No entry!
  console.warn("Page restricted, you need to sign in");
  next({ name: "login", query: { redirectFrom: to.fullPath } });
});

export default router;
