const getDefaultState = () => {
  return {
    userMailchimpInterests: {}
  };
};

export default {
  strict: false,
  namespaced: true,

  state() {
    return getDefaultState();
  },

  getters: {
    userMailchimpInterests: state => state.userMailchimpInterests
  },

  mutations: {
    SET_USER_MAILCHIMP_INTERESTS(state, value) {
      state.userMailchimpInterests = value;
    }
  },

  actions: {
    setUserMailchimpInterests({ commit }, interests) {
      commit("SET_USER_MAILCHIMP_INTERESTS", interests);
    }
  }
};
