import config from "../config";

export function pingMailchimp() {
  return fetch(`${config.NETLIFY_FNC_API_URL}mailchimpPing`)
    .then(response => {
      return response.json();
    })
    .catch(err => {
      console.log("error", err);
      return err.message;
    });
}

export function updateListMemberMailchimp(payload) {
  return fetch(`${config.NETLIFY_FNC_API_URL}mailchimpUpdateListMember`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(payload)
  })
    .then(response => {
      return response.json();
    })
    .catch(err => {
      console.log("error", err);
      return err.message;
    });
}

// Returns 404 if member email is already in the list
export function addMailchimp(payload) {
  return fetch(`${config.NETLIFY_FNC_API_URL}mailchimpAddMember`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(payload)
  })
    .then(response => {
      return response.json();
    })
    .catch(err => {
      console.log("error", err);
      return err.message;
    });
}

// Returns object of interests set on the PlanGap Inisghts Interests in MC groups
export function interestsFromInterestCategoryMailchimp() {
  return fetch(`${config.NETLIFY_FNC_API_URL}mailchimpInsightsInterests`)
    .then(response => {
      return response.json();
    })
    .catch(err => {
      console.log("error", err);
      return err.message;
    });
}

export function getMailchimpMember(payload) {
  return fetch(`${config.NETLIFY_FNC_API_URL}mailchimpGetListMember`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(payload)
  })
    .then(response => {
      return response.json();
    })
    .catch(err => {
      console.log("error", err);
      return err.message;
    });
}
