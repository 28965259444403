// import Vue from "vue";

const getDefaultState = () => {
  return {
    simulations: [
      {
        id: "a4539bea-b6a6-4e66-ae10-790b2378e6d5",
        scenario: "Means Test: $143,199+",
        timeline: "January 1, 2032",
        type: "Individual Claim",
        basis: "Legislative Action",
        thresholdDesc:
          "Legislation is enacted to reduce Social Security benefits if Modified Adjusted Gross Income (MAGI) is above $71,600 for single taxpayers or $143,199 for taxpayers filing jointly. The percentage reduction increases linearly from 0% for $71,600/$143,199 to 50% for $214,799/$429,528.",
        claimEligible: [
          "up to 11%",
          "up to 10%",
          "up to 8%",
          "up to 8%",
          "up to 7%"
        ],
        fileNamePrefix: "means-test",
        visualization: "chart",
        chartData: [
          {
            percent: 0,
            amount: 143199
          },
          {
            percent: 1,
            amount: 148927
          },
          {
            percent: 2,
            amount: 154655
          },
          {
            percent: 3,
            amount: 160383
          },
          {
            percent: 4,
            amount: 166111
          },
          {
            percent: 5,
            amount: 171839
          }
        ]
      },
      {
        id: "c20a26c6-3719-48d0-af43-eb11abc8b92d",
        scenario: "Full Retirement Age: Gradual Increase",
        type: "Group Claim",
        timeline: "January 1, 2033",
        basis: "Legislative Action",
        thresholdDesc:
          "Legislation is enacted to gradually increase Full Retirement Age for anyone born after 1975. Benefits are still available at age 62 at a reduced amount.",
        claimEligible: ["4%", "2%", "1%", "less than 1%", "less than 1%"],
        fileNamePrefix: "fra",
        visualization: "table"
      }
    ]
  };
};

export default {
  strict: false,
  namespaced: true,

  state() {
    return getDefaultState();
  },

  getters: {
    simulations: state => state.simulations
  },

  mutations: {
    RESET_SIMULATIONS_STATE(state) {
      Object.assign(state, getDefaultState());
    }
  },

  actions: {
    resetSimulationstate({ state, commit }) {
      commit("RESET_APP_STATE", state);
    }
  }
};
