import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import Highcharts from "highcharts";
import mapInit from "highcharts/modules/map";
import HighchartsVue from "highcharts-vue";
import mapData from "@highcharts/map-collection/countries/us/custom/us-all-territories.geo.json";
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import {
  confirmed,
  digits,
  email,
  min,
  max,
  regex
} from "vee-validate/dist/rules";
import BaseAppLayout from "@/layouts/BaseAppLayout";
import axios from "axios";
import attemptToAuthorizeTokens from "@/helpers/authorize-tokens";

Vue.prototype.$http = axios;

store.dispatch("auth/initAuth");

attemptToAuthorizeTokens();

mapInit(Highcharts);
Highcharts.maps["pgMockMap"] = mapData;
Vue.use(HighchartsVue);

Vue.filter("toCurrency", function(value) {
  if (typeof value !== "number") {
    return value;
  }
  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2
  });
  return formatter.format(value);
});

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

extend("confirmed", confirmed);
extend("digits", digits);
extend("email", email);
extend("min", min);
extend("max", max);
extend("regex", regex);

extend("required", {
  validate(value) {
    return {
      required: true,
      valid: ["", null, undefined].indexOf(value) === -1
    };
  },
  computesRequired: true
});

Vue.component("BaseAppLayout", BaseAppLayout);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
