/**
 * This module is responsible for all state and actions related to the current authenticated user.
 */
import GoTrue from "gotrue-js";
import { v4 as uuidv4 } from "uuid";

const getDefaultState = () => {
  return {
    currentUser: null,
    GoTrueAuth: null,
    emailChangeConfirmed: null,
  };
};

export default {
  strict: false,
  namespaced: true,

  state() {
    return getDefaultState();
  },
  getters: {
    loggedIn: (state) => !!state.currentUser,

    currentUser: (state) => state.currentUser,

    GoTrueAuth: (state) => state.GoTrueAuth,

    emailChangeConfirmed: (state) => state.emailChangeConfirmed,
  },
  mutations: {
    RESET_AUTH_STATE(state) {
      console.log("MUTATION", state);
      Object.assign(state, getDefaultState());
    },
    SET_GOTRUE(state, value) {
      state.GoTrueAuth = value;
    },

    SET_CURRENT_USER(state, value) {
      state.currentUser = value;
    },

    SET_EMAIL_CHANGE_CONFIRMED(state, value) {
      state.emailChangeConfirmed = value;
    },
  },
  actions: {
    resetAuthState({ state, commit }) {
      commit("RESET_AUTH_STATE", state);
    },
    /**
     * Authorize and login users via email
     * @param {*} store - vuex store object
     * @param {object} credentials - object containing email and password
     * @property {string} credentials.email - email of the user eg hello@email.com
     * @property {string} credentials.password - password string
     */
    attemptLogin({ commit, state }, credentials) {
      console.log(`Attempting login for ${credentials.email}`);
      return new Promise((resolve, reject) => {
        state.GoTrueAuth.login(credentials.email, credentials.password, true)
          .then((response) => {
            // console.log("THE GO TRUE LOGIN RESP ", response);
            let _currentUser = response;
            commit("SET_CURRENT_USER", _currentUser);
            resolve(response);
          })
          .catch((error) => {
            console.log("An error occurred signing in", error);
            reject(error);
          });
      });
    },

    /**
     * This confirms a new user from an invited user email by parsing the token which has been extracted from the Netlify
     * accept invite email.
     * @param {*} store - vuex store object
     * @param {string} token - token from accept invite email eg. "BFX7olHxIwThlfjLGGfaCA"
     */
    attemptAcceptInvite({ state }, token) {
      console.log("Attempting to verify token", token);
      // Create a temporary UUID PW to accept invite. It's never used. Invited user is directed to a form to create a PW.
      const tempPassword = uuidv4();

      return new Promise((resolve, reject) => {
        state.GoTrueAuth.acceptInvite(token, tempPassword, true)
          .then((response) => {
            console.log("User has accepted invite.");
            resolve(response);
          })
          .catch((error) => {
            console.log("An error occurred trying to accept invite", error);
            reject(error);
          });
      });
    },

    /**
     * This confirms a new user from an email signup by parsing the token which has been extracted from the Netlify
     * confirmation email.
     * @param {*} store - vuex store object
     * @param {string} token - token from confirmation email eg. "BFX7olHxIwThlfjLGGfaCA"
     */
    attemptConfirmation({ state }, token) {
      console.log("Attempting to verify token", token);
      return new Promise((resolve, reject) => {
        state.GoTrueAuth.confirm(token)
          .then((response) => {
            console.log("User has been confirmed");
            resolve(response);
          })
          .catch((error) => {
            console.log("An error occurred trying to confirm the user", error);
            reject(error);
          });
      });
    },

    /**
     * This confirms a new user from an email signup by parsing the token which has been extracted from the Netlify
     * confirmation email.
     * @param {*} state - vuex store object
     * @param {string} token - token from confirmation email eg. "BFX7olHxIwThlfjLGGfaCA"
     */
    attemptEmailChange({ state, commit }, token) {
      console.log("Attempting to do email change...", token);
      return new Promise((resolve, reject) => {
        const user = state.GoTrueAuth.currentUser();
        user
          .update({ email_change_token: token })
          .then((response) => {
            console.log("Updated user email change.");
            commit("SET_CURRENT_USER", response);
            resolve(response);
          })
          .catch((error) => {
            console.error("Failed to change user email: %o", error);
            reject(error);
          });
      });
    },

    /**
     * Sign out the current user if they are logged in.
     * TODO: Promisify this, and remove alert out. follow up UI changes should be handled outside of vuex
     * @param {*} store - vuex store object
     */
    attemptLogout({ state, commit }) {
      // Error signing out. Something to do with null currentUser in GoTruAuth at this point.
      // For now just resetting state and reloading the page via the component
      //console.log("User Object", state.GoTrueAuth.currentUser());
      //commit("RESET_AUTH_STATE", state);
      return new Promise((resolve, reject) => {
        state.GoTrueAuth.currentUser()
          .logout()
          .then((resp) => {
            console.log("User logged out", resp);
            commit("SET_CURRENT_USER", null);
            //commit("RESET_AUTH_STATE", state);

            resolve(resp);
          })
          .catch((error) => {
            console.error("Could not log user out", error);
            commit("SET_CURRENT_USER", null);
            //commit("RESET_AUTH_STATE", state);
            reject(error);
          });
      });
    },

    /**
     * Initialises a GoTrue instance. This method also checks if user is in a local environment  based on the URL.
     * this updates the `app/SET_DEV_ENV` flag. This facilitates a zero-config setup as a developer can input their
     * netlify URL in the UI (see the the `SetNetlifyURL.vue` component).
     * @param {*} store - vuex store object
     */
    initAuth({ commit, rootGetters }) {
      // https://stackoverflow.com/questions/5284147/validating-ipv4-addresses-with-regexp/57421931#57421931
      const IPv4Pattern = /\b((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.){3}(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\b/;
      const hostName = document.location.hostname;
      const APIUrl = `https://${hostName}/.netlify/identity`;
      const initNewGoTrue = (APIUrl) => {
        return new GoTrue({
          APIUrl: APIUrl,
          audience: "",
          setCookie: true,
        });
      };

      // Detect if app is being run in a development environment, if so a flag is set to indicate this so that it is
      // possible to set the URL for the netlify identity in the login component.
      // TODO : Move this logic into a separate action.
      if (hostName.match(IPv4Pattern) || hostName === "localhost") {
        console.log("Looks like your in a dev environment", hostName);
        commit("app/SET_DEV_ENV", true, { root: true });

        console.log(
          "Initialising Go True client with",
          `https://${rootGetters["app/siteURL"]}/.netlify/identity`
        );
        commit(
          "SET_GOTRUE",
          initNewGoTrue(
            `https://${rootGetters["app/siteURL"]}/.netlify/identity`
          )
        );

        this.subscribe((mutation) => {
          if (mutation.type === "app/SET_SITE_URL") {
            console.log(
              "Re-initialising Go True client with",
              rootGetters["app/siteURL"]
            );
            commit(
              "SET_GOTRUE",
              initNewGoTrue(
                `https://${rootGetters["app/siteURL"]}/.netlify/identity`
              )
            );
          }
        });

        return;
      }

      console.log("Initialising Go True client with ", APIUrl);
      commit("SET_GOTRUE", initNewGoTrue(APIUrl));
    },

    requestPasswordRecover({ state }, email) {
      return new Promise((resolve, reject) => {
        state.GoTrueAuth.requestPasswordRecovery(email)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            console.error("Failed to verify recover token: %o", error);
            reject();
          });
      });
    },

    attemptPasswordRecovery({ state, commit }, token) {
      return new Promise((resolve, reject) => {
        state.GoTrueAuth.recover(token)
          .then((response) => {
            console.log("Signing in user with recovery token");
            commit("SET_CURRENT_USER", response);
            resolve(response);
          })
          .catch((error) => {
            console.error("Failed to verify recover token: %o", error);
            reject();
          });
      });
    },

    updateUserAccount({ state, commit }, userData) {
      //TODO : fix bug in this action - https://github.com/chiubaca/vue-netlify-fauna-starter-kit/issues/12
      return new Promise((resolve, reject) => {
        const user = state.GoTrueAuth.currentUser();
        user
          .update(userData)
          .then((response) => {
            console.log("Updated user account details");
            commit("SET_CURRENT_USER", response);
            resolve(response);
          })
          .catch((error) => {
            console.error("Failed to update user account: %o", error);
            reject(error);
          });
      });
    },

    // To keep track of the email before user changed it,
    // so it can be used to update Mailchimp email and show in UI
    // EG, User email was initially: abc@123.com and they want to change to
    // xyz@123.com. We need abc@123.com
    updateEmailChangeConfirmed({ commit }, payload) {
      console.log("IN store: updateEmailChangeConfirmed", payload);
      commit("SET_EMAIL_CHANGE_CONFIRMED", payload);
    },
  },
};
